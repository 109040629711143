<template>
<div class="m-2">
  <div class="form-inline sub-mr-2">
    <div>Local:</div>
    <video :srcObject.prop="stream" playsinline autoplay muted controls/>
    <div>Replay:</div>
    <video ref="replayVideo" playsinline autoplay muted controls/>
  </div>
  <hr/>
  <!-- Recording -->
  <div>
    <h2>Recording</h2>
    <div>Device: {{ device }}</div>
    <div class="form-inline sub-mr-2 sub-mt-2">
      <div>Codec: </div>
      <b-form-select v-model="mimeType" :options="mimeTypeList"></b-form-select>
      <div>Custom: </div>
      <b-input type="text" placeholder="Codec override" v-model="customMimeType"/>
      <div>Ext: </div>
      <b-input type="text" placeholder="(default: msb)" v-model="customExtension"/>
      <div>Segment Length: </div>
      <b-input type="number" v-model="segmentLength"/>
      <b-checkbox type="checkbox" v-model="uploadManifest">Upload Manifest</b-checkbox>
    </div>
  </div>
  <div class="form-inline sub-mr-2 sub-mt-2" v-if="false">
    <b-button @click="record" :disabled="isRecording">Record</b-button>
    <b-button @click="stop" :disabled="!isRecording">Stop</b-button>
    <b-button @click="clear">Clear</b-button>
    <b-button @click="replay">Replay</b-button>
  </div>
  <div class="form-inline sub-mr-2 sub-mt-2">
    <div>Remote Folder:</div>
    <b-form-select v-model="remoteFolder" :options="folderList"/>
    <div>Custom: </div>
    <b-input type="text" placeholder="Folder override" v-model="customRemoteFolder"/>
    <b-button @click="recordRemote">Record Remote</b-button>
    <b-button @click="stop" :disabled="!isRecording">Stop</b-button>
  </div>
  <hr/>
  <h2>Playback</h2>
  <div class="form-inline sub-mr-2 sub-mt-2">
      <div>Codec: </div>
      <b-form-select v-model="mimeType" :options="mimeTypeList"></b-form-select>
      <div>Custom: </div>
      <b-input type="text" placeholder="Codec override" v-model="customMimeType"/>
  </div>
  <div class="form-inline sub-mr-2 sub-mt-2">
    <div>Remote Folder:</div>
    <b-form-select v-model="remoteFolder" :options="folderList"/>
    <div>Custom: </div>
    <b-input type="text" placeholder="Folder override"  v-model="customRemoteFolder"/>
    <b-button @click="replayRemote">Replay Remote</b-button>
  </div>
  <div class="form-inline sub-mr-2 sub-mt-2">
    <div>Load stream0.mp4 from folder: </div>
    <b-input class="w-50" type="text" placeholder="Replay Remote URL override" v-model="replayRemoteURL"/>
  </div>
  <hr/>
  <!-- https://firebasestorage.googleapis.com/v0/b/hh-streams/o/tests-msb%2Fchrome%2Fstream.m3u8?alt=media -->
  <a target="_blank" href="http://localhost:8080/tests/test-hls/?debug=1&url=https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fhh-streams%2Fo%2Ftests-msb%252Fchrome%252Fstream.m3u8%3Falt%3Dmedia">Player</a>
  <div v-for="(l,i) in manifest" :key="'manifest_'+i">
    {{ l }}
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog('msb');
import { getUserMedia } from "@/services/mediautils";
import { fetchBlob, getBrowser } from '@/services/utils';
import { storage } from "@/services/db";

export default {
  data() {
    return {
      folderList: [
        'chrome',
        'mobilechrome',
        'mobilesafari',
        'safari',
      ],
      mimeTypeList: [
        '(autodetect)',
        'video/webm;codecs=h264',
        'video/webm;codecs=vp9,opus',
        'video/mp4',
      ],
      playbackOptions: [
        'MediaSource',
        'video.src',
        'HLS.js',
        'Shaka',
      ],

      device: null,
      segmentLength:2000,
      gsRoot: 'gs://hh-streams/tests-msb',
      gsHttpRoot: 'https://firebasestorage.googleapis.com/v0/b/hh-streams/o',
      mimeType: '(autodetect)',
      uploadManifest: true,

      customMimeType: "",
      customRemoteFolder: "",
      customExtension: "",
      replayRemoteURL: "",

      stream: null,
      buffers: [],
      replayStream: null,
      remoteFolder: null,
      mediaRecorder: null,
      isRecording: false,
      manifest: null,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.device = getBrowser();
      log.log("device", this.device);
      this.remoteFolder = this.device;
      this.stream = await getUserMedia({video:true, audio:true, definition:'low'})
      this.mimeType = this.getMimeType();
    },
    getMimeType() {
      if (this.mimeType == '(autodetect)') {
        for (let i in this.mimeTypeList) {   
          if (MediaRecorder.isTypeSupported(this.mimeTypeList[i])) {
            this.mimeType = this.mimeTypeList[i];
            break;
          }
        }
        log.log(`Autodetected ${this.mimeType}`);
      }
      return this.mimeType;
    },
    record() {
      if (this.mediaRecorder) {
        delete this.mediaRecorder;
        this.isRecording = false;
      }
      this.mediaRecorder = new MediaRecorder(this.stream, {
        mimeType: this.mimeType,
        videoBitsPerSecond : 3000000,
        sampleRate: 44100,
      });
      log.log("mediaRecorder", this.mediaRecorder.mimeType);

      this.mediaRecorder.ondataavailable = (e) => {
        log.log("mediaRecorder dataavailable", e);
        this.buffers.push(e.data);
      };

      this.mediaRecorder.onstop = () => {
        log.log("mediaRecorder onstop");
        this.isRecording = false;
      };

      this.mediaRecorder.start(1000);
      this.isRecording = true;
    },
    stop() {
      log.log('stop');
      this.mediaRecorder?.stop();
      this.isRecording = false;
    },
    clear() {
      this.buffers = [];
    },
    replay() {
      let ms = new MediaSource();
      log.log("MediaSource", ms);
      let mimeType = this.mimeType;
      let buffers = this.buffers.slice(); // clone to modify
      this.$refs.replayVideo.src = URL.createObjectURL(ms);

      ms.addEventListener('sourceopen', function () {
        log.log("MediaSource onsourceopen typeSupported=", MediaSource.isTypeSupported(mimeType));
        let sb = ms.addSourceBuffer(mimeType);

        async function addNextBuffer() {
          log.log("addNextBuffer buffers.length=", buffers.length)
          if (!buffers.length)
            return;
          let b = buffers.shift();
          sb.appendBuffer(await b.arrayBuffer());
        }

        sb.onupdateend = addNextBuffer;
        addNextBuffer();
      });
    },
    recordRemote() {
      let mimeType = this.customMimeType || this.mimeType;
      this.mediaRecorder = new MediaRecorder(this.stream, {
        mimeType,
        videoBitsPerSecond : 3000000,
        sampleRate: 44100,
      });
      this.count = 0;
      this.manifest = null;
      log.log("mediaRecorder", this.mediaRecorder.mimeType);
      let remoteFolder = this.customRemoteFolder || this.remoteFolder;
      let extension = this.customExtension || 'msb';

      this.mediaRecorder.ondataavailable = (e) => {
        log.log("mediaRecorder dataavailable", e);
        this.uploadFile(`${this.gsRoot}/${remoteFolder}/stream${this.count}.${extension}`, e.data);
        if (this.uploadManifest)
          this.uploadSegment(remoteFolder, extension);
        if (!this.count)
          this.uploadDesc(remoteFolder, mimeType);
        this.count += 1;
      };

      this.mediaRecorder.start(this.segmentLength);
      this.isRecording = true;
    },
    async uploadFile(path, blob) {
      log.log("uploadFile", path, blob);
      let res = await storage.refFromURL(path).put(blob);
      log.log("uploadFile result=", res);
    },
    uploadDesc(remoteFolder, mimeType) {
      let desc = JSON.stringify({
        mimeType
      });
      this.uploadFile(`${this.gsRoot}/${remoteFolder}/stream.json`, new Blob([desc]));
    },
    uploadSegment(remoteFolder, extension) {
      if (!this.manifest) {
        this.manifest = [
          '#EXTM3U\r\n',
          '#EXT-X-VERSION:3\r\n',
          '#EXT-X-TARGETDURATION:1\r\n',
          '#EXT-X-MEDIA-SEQUENCE:0\r\n',
          '#EXT-X-PLAYLIST-TYPE:EVENT\r\n',
          '#EXT-X-DISCONTINUITY\r\n',
        ];
      }
      let file = encodeURIComponent(`tests-msb/${remoteFolder}/stream${this.count}.${extension}`);
      this.manifest.push(`#EXTINF:${this.segmentLength / 1000},\r\n${file}?alt=media\r\n`);
      this.uploadFile(`${this.gsRoot}/${remoteFolder}/stream.m3u8`, new Blob(this.manifest));
    },
    replayRemote() {
      this.replayCount = 0;
      let that = this;
    
      let ms = new MediaSource();
      log.log("MediaSource", ms);
      let mimeType = this.mimeType;
      this.$refs.replayVideo.src = URL.createObjectURL(ms);

      ms.addEventListener('sourceopen', function () {
        log.log("MediaSource onsourceopen", mimeType, "typeSupported=", MediaSource.isTypeSupported(mimeType));
        let sb = ms.addSourceBuffer(mimeType);

        async function addNextBuffer() {
          let path = "";
          if (that.replayRemoteURL) {
            //https://streamozoa.ngrok.io/tests-msb/mobilechrome/stream0.mp4
            path = `${that.replayRemoteURL}/stream${that.replayCount}.mp4`;
          } else {
            //https://firebasestorage.googleapis.com/v0/b/hh-streams/o/tests-msb%2Fchrome%2Fstream0.msb?alt=media
            let file = encodeURIComponent(`tests-msb/${that.remoteFolder}/stream${that.replayCount}.msb`);
            path = `${that.gsHttpRoot}/${file}?alt=media`;
          }
          try {
            log.log("addNextBuffer", path);
            let b = await fetchBlob(path);
            log.log("fetchBlob", b);
            let ab = await b.arrayBuffer();
            log.log("arrayBuffer", ab, sb);
            sb.appendBuffer(ab);
            that.replayCount += 1;
          } catch (e) {
            log.log('addNextBuffer error', e);
          }
        }

        sb.onerror = function(ev) {
          log.log("SB error=", ev);
        };
        sb.onupdateend = addNextBuffer;
        addNextBuffer();
      });
    }
  }
}
</script>

<style>

</style>